.progress-bar {
    transition: width 3s ease; /* Genişlik değişimi için animasyon */
    display: flex;
    align-items: center; /* Yatayda ortalama */
}

.progress-bar.yellow {
    background-color: yellow; /* Sarı renk */
}

.progress-bar.green {
    background-color: green; /* Yeşil renk */
}

.progress-bar.red {
    background-color: red; /* Kırmızı renk */
}
